<template>
    <div class="box">
        <h2>
            退换破损单
            <!-- <div class="order-right">
                <div class="search-box">
                    <input type="text" v-model="keyword" placeholder="请输入您要搜索的商品" />
                    <el-button
                        @click="
                            loading = true
                            getGoodsSkuPage()
                        "
                        >搜索</el-button
                    >
                </div>
            </div> -->
        </h2>
        <div class="form-row">
            <div class="form-item" style="margin-right: 30px">
                <span>单号</span>
                <el-input size="small" v-model="keyword" name="keyword" placeholder="请输入订单号" style="width: 80%"></el-input>
            </div>
            <div class="form-item" style="margin-right: 30px">
                <span>处理结果</span>
                <el-select v-model="message" name="message" style="width: 120px" size="small" class="get-method" placeholder="所有">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </div>
            <div class="form-item">
                <span>时间范围</span>
                <el-date-picker v-model="timeArea" size="small" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                <el-button class="search-btn" @click="search()"> <img src="~img/alibaba/search.png" />搜索</el-button>
                <el-button class="search-btn" style="width: 140px" @click.native="dialogVisible1 = true"> <img src="~img/alibaba/search.png" />退货/破损申请</el-button>
            </div>
        </div>
        <div class="table">
            <!-- <div v-for="item in shouhoulist" :key="item.id"> -->
            <el-table :data="shouhoulist" :header-cell-style="{ background: 'rgb(250,250,250)', color: '#333333' }" class="top1">
                <el-table-column prop="aftermarket_no" label="售后单号" align="center"> </el-table-column>
                <el-table-column label="申请日期" align="center">
                    <template slot-scope="scope">
                        {{ $util.timeStampTurnTime(scope.row.create_time) }}
                    </template>
                </el-table-column>
                <el-table-column prop="old_order_no" label="原始订单号" align="center"> </el-table-column>
                <el-table-column prop="reason" label="申请事由" align="center"> </el-table-column>
                <el-table-column prop="apply_price" label="申请金额" align="center"> </el-table-column>
                <el-table-column prop="verify_price" label="审核金额" style="color: red" align="center"> </el-table-column>
                <el-table-column prop="finish_price" label="已结算金额" align="center"> </el-table-column>
                <el-table-column prop="" label="未结算金额" align="center">
                    <template slot-scope="scope">
                        {{ (scope.row.verify_price - scope.row.finish_price).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column prop="handle_way" label="处理方式/客服备注" align="center" width="150px"> </el-table-column>
                <el-table-column prop="status" label="处理结果" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">待审核</span>
                        <span v-if="scope.row.status == 2">已审核</span>
                        <span v-if="scope.row.status == 3">已完成</span>
                    </template>
                </el-table-column>
            </el-table>
            <!-- </div> -->
        </div>
        <!-- 退货/破损申请弹窗 -->
        <div id="app-frist">
            <el-dialog title="退货/破损申请" :visible.sync="dialogVisible1" width="70%" :before-close="handleClose_message">
                <div class="form-item">
                    <div class="form_item">
                        <div class="number_order">
                            <span class="number_order_name">原始订单号</span>
                            <el-input size="small" v-model="order_no" name="order_no" placeholder="请输入订单编号" class="number_order_text"></el-input>
                        </div>
                        <div class="number_order">
                            <span class="number_order_name">申请金额</span>
                            <el-input size="small" v-model="apply_price" name="apply_price" placeholder="请输入申请金额" class="number_order_text" readonly="readonly">{{ totalPrice }}</el-input>
                        </div>
                        <div class="number_order">
                            <span class="font_css number_order_name">申请事由</span>
                            <template>
                                <el-radio-group v-model="reason" class="number_order_text">
                                    <el-radio :label="1">破损</el-radio>
                                    <el-radio :label="2">少货</el-radio>
                                    <el-radio :label="3">退货</el-radio>
                                    <el-radio :label="4">其他</el-radio>
                                </el-radio-group>
                            </template>
                        </div>
                    </div>
                    <div>
                        <div class="number_order">
                            <span class="number_order_name font_css">备注</span>
                            <el-input size="small" v-model="beizhu" name="beizhu" placeholder="请输入备注" class="number_order_text" style="float: right"></el-input>
                        </div>
                    </div>
                    <div>
                        <div class="number_order">
                            <span class="font_css number_order_name">退货商品</span>
                            <el-button class="btn-search" @click="shouhougoodsList()">搜索</el-button>
                            <el-input size="small" v-model="sku_no" name="sku_no" placeholder="请输入国际条码" class="number_order_text" style="width: 60%; margin-right: 0px"></el-input>
                        </div>
                    </div>
                    <div class="table">
                        <el-table :data="shougoodshoulist" :header-cell-style="{ background: 'rgb(250,250,250)', color: '#333333' }" class="top1">
                            <el-table-column label="商品图片" align="center">
                                <template slot-scope="scope">
                                    <img :src="$img(scope.row.sku_image)" min-width="70" height="70" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="sku_no" label="国际条码" align="center"> </el-table-column>
                            <el-table-column prop="goods_name" label="商品名称" align="center"> </el-table-column>
                            <el-table-column prop="order_no" label="商品货号" align="center"> </el-table-column>
                            <el-table-column prop="color_name" label="商品颜色" align="center"> </el-table-column>
                            <el-table-column prop="price" label="您的价格" align="center" class="popup_font_color"> </el-table-column>
                            <el-table-column prop="num" width="150px" label="售后数量" align="center">
                                <template slot-scope="scope">
                                    <div class="cart-flex">
                                        <el-input-number size="mini" v-model="scope.row.num" @change="calculationTotalPrice()" :step="1" :min="0"></el-input-number>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" class="popup_font_color" label="售后申请金额">
                                <template slot-scope="scope">
                                    {{ (scope.row.price * scope.row.num).toFixed(2) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-button @click.native.prevent="deleteRow(scope.$index, shougoodshoulist)" type="text" size="small"> 取消 </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <span class="popup_footer">
                    <el-button type="primary" @click="addShouhoudan()" class="submit_yes">提交申请</el-button>
                    <el-button type="primary" @click="dialogVisible1 = false" class="submit_no">取消</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="jump" :data="shouhoulist">
            <el-pagination class="jump-count" :page-size="pageSize" @current-change="handlePageChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" ref="pagination" :total="total"> </el-pagination>
        </div>
    </div>
</template>

<script>
import { balance, withdrawConfig, balanceDetail, shouhouList, shouhougoodsList, addShouhoudan as SaddShouhoudan, delGood } from "@/api/member/account"
import axios from "axios"

export default {
    name: "account",
    components: {},
    data: () => {
        return {
            list: [],
            loading: true,
            yes: true,
            pageSize: 7,
            page_size: 0,
            totalPrice: "0.00",
            totalCount: 0,
            page: "",
            loading: true,
            checkAll: false,
            keyword: "",
            keywords: "",
            dialogVisible1: false,
            beizhu: "",
            shouhoulist: [],
            currentPage: 1,
            total: 0,
            options: [
                { label: "全部", value: "all" },
                { label: "待审核", value: "1" },
                { label: "已审核", value: "2" },
                { label: "已完成", value: "3" }
            ],
            order_no: "",
            sku_no: "",
            apply_price: "",
            goods_id: "",
            timeArea: [],
            num: "",
            beginTime: "",
            endTime: "",
            reason: 1,
            shougoodshoulist: [],
            addshouhoulist: [],
            value: "",
            pickerOptions: "",
            value2: "",
            message: "all",
            msg: "", //记录每一条的信息，便于取id
            delarr: [], //存放删除的数据
            shouhoulist: []
        }
    },
    created() {
        this.getAccount()
        this.getAccountList()
        this.shouhouList()
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        deleteRow(index, rows) {
            rows.splice(index, 1)
        },
        //售后破损单列表
        shouhouList() {
            shouhouList({
                page: this.currentPage,
                page_size: this.pageSize,
                keyword: this.keyword,
                begin_time: this.beginTime,
                end_time: this.endTime,
                method: this.message
            })
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.shouhoulist = res.data.list
                        this.total = res.data.count
                    }
                    console.log(this.shouhoulist)
                    this.loading = false
                })
                .catch((res) => {
                    this.loading = false
                })
        },
        search() {
            console.log(this.timeArea.length)
            if (this.timeArea.length == 0) {
                this.beginTime = "946656000"
                this.endTime = "4070880000"
            } else {
                this.beginTime = this.timeArea[0].getTime() / 1000
                this.endTime = this.timeArea[1].getTime() / 1000
            }
            this.loading = true
            this.shouhouList()
        },
        // 申请退货破损单商品
        shouhougoodsList() {
            shouhougoodsList({
                page: this.currentPage,
                page_size: this.pageSize,
                keyword: this.keyword,
                order_no: this.order_no,
                sku_no: this.sku_no
            }).then((res) => {
                if (res.code >= 0 && res.data) {
                    // console.log(res.data)
                    this.total = res.data.count
                    this.shougoodshoulist = res.data
                    this.calculationTotalPrice()
                }
            })
        },
        // 取消商品破损单信息
        del() {
            for (let i in this.shougoodshoulist) {
                console.log(i)
            }

            // delGood({
            //     // order_goods_id:this.shougoodshoulist[0].order_goods_id
            // })
            //     .then((res) => {
            //         console.log(res)
            //         if (res.code == 0) {
            //             console.log(res)
            //         }
            //         // this.shouhougoodsList()
            //     })
            //     .catch((err) => {
            //         console.log(err)
            //     })
        },
        //单行删除

        handleDelete(index, row) {
            this.idx = index

            this.shougoodshoulist = row //每一条数据的记录

            this.delarr.push(this.shougoodshoulist.order_no) //把单行删除的每条数据的id添加进放删除数据的数组
        },
        // 计算购物车总价
        calculationTotalPrice() {
            console.log(this.shougoodshoulist)
            if (this.shougoodshoulist.length) {
                let totalPrice = 0,
                    totalCount = 0,
                    siteAllElectionCount = 0
                // console.log(this.shougoodshoulist)
                // this.shougoodshoulist.forEach((siteItem) => {
                let siteGoodsCount = 0
                this.shougoodshoulist.forEach((item) => {
                    // if (item.checked) {
                    siteGoodsCount += 1
                    totalCount += 1
                    totalPrice += item.price * item.num
                    // console.log(123)
                    // }
                    // console.log(item.cart_id)
                    // console.log(item.price)
                    // console.log(item.num)
                    // console.log(totalPrice)
                    // console.log(siteGoodsCount)
                    // editNumber({
                    //     cart_id: item.cart_id,
                    //     num: item.num
                    // }).then((res) => {
                    //     if (res.code >= 0 && res.data) {
                    //         console.log(res)
                    //     }
                    // })
                })
                // if (this.shougoodshoulist.length == siteGoodsCount) {
                //     this.checked = true
                //     siteAllElectionCount += 1
                // } else {
                //     this.checked = false
                // }
                // })
                this.apply_price = totalPrice.toFixed(2)
                this.totalCount = totalCount
                // this.checkAll = this.cartList.length == siteAllElectionCount
                console.log(this.totalPrice)
            } else {
                this.totalPrice = "0.00"
                this.totalCount = 0
            }
            this.modifyNum = 1
        },
        // 申请退货破损单商品
        // addShouhoudan() {
        //     let obj = {}

        //     for (let i in this.shougoodshoulist) {
        //         obj[i] = { sku_id: this.shougoodshoulist[i].sku_id, num: this.shougoodshoulist[i].num }
        //     }
        //     let sku_goods = {}
        //     sku_goods = obj
        //     addShouhoudan({
        //         sku_goods,
        //         page: this.currentPage,
        //         page_size: this.pageSize,
        //         keyword: this.keyword,
        //         order_no: this.order_no,
        //         sku_no: this.sku_no,
        //         apply_price: this.apply_price,
        //         reason: this.reason,
        //         beizhu: this.beizhu
        //     })
        //         .then((res) => {
        //             if (res.code == 0 && res.data) {
        //                 console.log(123456789)
        //             }
        //         })
        //         .catch((err) => {
        //             console.log(123)
        //         })
        // },
        addShouhoudan() {
            let obj = {}

            for (let i in this.shougoodshoulist) {
                obj[i] = { sku_id: this.shougoodshoulist[i].sku_id, num: this.shougoodshoulist[i].num }
            }
            let sku_goods = {}
            sku_goods = obj

           SaddShouhoudan(
               {
                 sku_goods,
                 page: this.currentPage,
                 page_size: this.pageSize,
                 keyword: this.keyword,
                 order_no: this.order_no,
                 sku_no: this.sku_no,
                 apply_price: this.apply_price,
                 reason: this.reason,
                 beizhu: this.beizhu
               }
           )
              .then((res) => {
                this.dialogVisible1 = false
                this.loading = false
                this.$message.success(res.message)
              })
              .catch((err) => {
                this.loading = false
                this.$message.error(err.message)
              })
        },
        numChange() {
            console.log(this.shougoodshoulist)
        },
        //获取余额信息
        getAccount() {
            balance({ account_type: "balance,balance_money" })
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.balanceInfo = res.data
                        const price = (parseFloat(this.balanceInfo.balance) + parseFloat(this.balanceInfo.balance_money)).toFixed(2)
                        let priceSplit = price.split(".")
                        this.integer = priceSplit[0]
                        this.decimal = priceSplit[1]
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        //获取余额明细
        getAccountList() {
            balanceDetail({
                // page_size: this.account.page_size,
                // page: this.account.page,
                account_type: "balance"
            })
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.accountList = res.data.list
                        this.total = res.data.count
                        this.accountList.forEach((item) => {
                            item.time = this.$util.timeStampTurnTime(item.create_time)
                        })
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },
        getGoodsSkuPage() {
            goodsSkuPage(
                {
                    brand_id: this.brand_id,
                    origin: this.origin,
                    category_id: this.category_id,
                    order: this.orderType || "",
                    sort: this.orderSort || "",
                    keyword: this.keyword || "",
                    min_price: this.minPrice,
                    max_price: this.maxPrice,
                    page_size: this.pageSize,
                    page: this.page
                },
                0,
                "GET"
            )
                .then((res) => {
                    if (res.code == 0) {
                        this.goodsList = res.data
                        this.total = res.data.count
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.$message.error(err.message)
                    this.loading = false
                })
        },
        handleClose_message(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done()
                })
                .catch((_) => {})
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done()
                })
                .catch((_) => {})
        },
        // handlePageSizeChange(num) {
        //     // this.account.page_size = num
        //     this.pageSize = num
        //     this.getAccountList()
        // },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
            this.getAccountList()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.refresh()
        },
        applyWithdrawal() {
            this.$router.push("/member/apply_withdrawal")
        },
        rechargeList() {
            this.$router.push("/member/recharge_list")
        },
        handlePageChange() {
            this.shouhouList()
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .order-right {
            font-size: $ns-font-size-lg;
            color: $ns-text-color-gray;
            display: flex;
            align-items: center;

            > span {
                color: $base-color;
                margin: 0 5px;
            }

            .search-box {
                display: flex;
                align-items: center;
                margin-right: 15px;

                input {
                    border: 1px solid $base-color-gray;
                    width: 200px;
                    height: 40px;
                    box-sizing: border-box;
                    padding: 0 12px;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    outline: none;
                }

                .el-button {
                    height: 40px;
                    width: 70px;
                    background-color: $base-color;
                    font-size: $ns-font-size-base;
                    color: #ffffff;
                    text-align: center;
                    border-radius: 0px;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }
        }
    }

    .form-row {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .form-item {
            display: flex;
            align-items: center;
            color: $ns-text-color-black;

            span {
                margin-right: 10px;
            }

            .search-btn {
                height: 32px;
                width: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                margin-left: 10px;

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }

    .table {
        // margin-top: 30px;
        margin-bottom: 30px;
    }

    .el-pagination {
        margin-top: 30px;
    }

    .default {
        color: #c2c2c2;
    }
    .success {
        color: #6c9a12;
    }
    .failure {
        color: #eaa12d;
    }
}
.top1 {
    width: 1240px !important;
}
.number_order {
    float: left;
    width: 50%;
    margin: 10px 0px;
}
.number_money {
    float: right;
    width: 50%;
}
.form_item {
    margin: 20px 0px;
}
.font_css {
    color: #333;
    font-weight: bold;
}
.number_order_name {
    margin-right: 20px;
    color: #333;
    font-weight: bold;
}
.number_order_text {
    width: 70%;
    float: right;
    margin-right: 10%;
    height: 32px;
    line-height: 32px;
}
.btn-search {
    width: 10%;
    float: right;
    margin-right: 10%;
    height: 32px;
    background-color: #ed6030;
    line-height: 8px;
    color: #fff;
}
.popup_footer {
    .submit_yes {
        width: 150px;
        margin-left: 33%;
    }
    .submit_no {
        width: 150px;
        margin-left: 10%;
        background-color: #aaa;
        color: #333;
        border-color: #fff;
    }
}
.popup_font_color {
    color: #ed6030 !important;
}
</style>
